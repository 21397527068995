var readSet = 0;
var writeSet = 0;
var deleteSet = 0;
var baseURL = '';
var adminUrl = '';

$(document).ready(function(){
  
  baseURL = funGetValueWe("txtBaseUrl");
  adminUrl = funGetValueWe("txtAdminUrl");

  $('.date').datepicker({'autoHide':true,'format':'dd-mm-yyyy'});

    $("#formResetPassword").submit(function(event){
      var password = funGetValueWe('txt_new_password');
      var confirm = funGetValueWe('txt_confirm_password');
      if(password != confirm) {
        fun_error_message("password and confirm does mot match");
      }
      else {
        $.ajax({                                // Ajax call
            type: "POST",
            dataType: "JSON",
            url: baseURL+adminUrl+"/ajax/reset-password",
             data:'_token='+funGetToken()+'&password='+password,
                success: function(response) {
                    if(response.status==true)
                    {   
                        // $("#span_log_count").css("display","none");
                        funSetValue("txt_new_password",'');
                        funSetValue("txt_confirm_password",'');
                        funCloseModal("modalResetPassword");
                        funSuccessMessage(response.message);
                    }
                    else
                    {
                        funErrorMessage(response.message);
                    }   
                },
            error: function() {
                funHideLoader();
                funErrorMessage("Error occured !!! Please try after sometime.");
            }
        });
      }
      event.preventDefault();
    });
});

function fun_check_all(type)
{
  if(type=='read' && readSet == 0) {
    $(".checkRead").prop("checked",true);
    readSet = 1;
  }
  else if(type=='read' && readSet == 1) {
    $(".checkRead").prop("checked",false);
    readSet = 0;
  }

  if(type=='write' && writeSet == 0) {
    $(".checkWrite").prop("checked",true);
    writeSet = 1;
  }
  else if(type=='write' && writeSet == 1) {
    $(".checkWrite").prop("checked",false);
    writeSet = 0;
  }

  if(type=='delete' && deleteSet == 0) {
    $(".checkDelete").prop("checked",true);
    deleteSet = 1;
  }
  else if(type=='delete' && deleteSet == 1) {
    $(".checkDelete").prop("checked",false);
    deleteSet = 0;
  }

}

function funLogout()
{
    funOpenModal("modalSystemLogout");
}

function funOpenModal(id,arg)
{
  if(arg == true){
    $('#'+id).modal({backdrop: 'static', keyboard: false});
  }
   
  $("#" + id).modal("show");
  
}

function funCloseModal(id)
{
  $("#"+id).modal("hide");
}

function funLogoutConfirm()
{
    var base_url = funGetValueWe("txtBaseUrl");
    window.location = base_url+adminUrl+'/logout';
}

function funGetValueWe(id)
{
  return $.trim($("#"+id).val());
}

function funGetToken()
{
    return $("input[name=_token]").val();
}

function funShowLoader()
{
  $('body').addClass('loading').addClass('overlay').loader('show', {
      loader: false,
    overlay: true
  });
  return true;
}

function funHideLoader()
{
  $('body').removeClass('loading').loader('hide');
  return true;
}

function funSuccessMessage(msg)
{
     toastr.clear();
      toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": true,
      "positionClass": "toast-bottom-center",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }
    toastr.success(msg);
    return true;
}

function funErrorMessage(msg) {
    toastr.clear();
   toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": true,
      "positionClass": "toast-bottom-center",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }
    toastr.error(msg);
    return true;
}

function funSetAdminLeftBar()
{
  var token = $("input[name='_token']").val();
  $.ajax({                // Ajax call
    type: "GET",
    url: baseURL+adminUrl+"/ajax/set-admin-left-menu",
    data: '_token='+token,
    success: function(response) {
        
    },
    error: function() {
      console.log("Error occured !!! Please try after sometime.");
    }
  });
}

function funSetValue(id,value)
{
  $("#"+id).val(value);
}

function funGetValue(id)
{
  return encodeURIComponent($.trim($("#"+id).val()));
}